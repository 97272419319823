<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0">
			<h3 class="card-title font-weight-bolder">
				Network Bandwidth -
				<span v-if="network && network.name == 'All Networks Total'" class="pl-2">{{ network.name }}</span>
				<span v-else-if="network" class="pl-2">
					<router-link :to="{ name: 'network', params: { serverId: network.server_id, networkId: network.NetworkId } }">
						{{ network.name }}
					</router-link>
				</span>
			</h3>
			<div class="col-3 d-flex align-items-center">
				<div style="width: 100%">
					<b-form-select v-model="selectedNetwork" :options="networks" @change="changeNetwork"></b-form-select>
				</div>
			</div>
		</div>
		<div class="card-body pt-2">
			<ITCSpinner :loaded="loaded">
				<template #default>
					<Dygraphs v-if="network.data.length > 0" :data="network.data" :options="dyOpts" />
					<div v-else class="d-flex align-items-center justify-content-center" style="height: 350px">
						<h3>No Data for {{ network.name }}</h3>
					</div>
				</template>
			</ITCSpinner>
		</div>
	</div>
</template>

<script>
//Converts each timestamp into a date object for dygraphs and converts sql timestamp to have / so it works in safari
const process = data => {
	data.forEach(network => {
		if (network.data && network.data.length > 0) {
			network.data.forEach(row => {
				row[0] = new Date(row[0].replaceAll('-', '/'));
			});
		}
	});
	return data;
};

export default {
	name: 'NetworkTrafficGraph',
	components: {
		Dygraphs: () => import('@/view/content/lib/dygraphs.vue'),
		ITCSpinner: () => import('@/view/content/loaders/itcSpinner.vue'),
	},
	data() {
		return {
			loaded: false,
			networkData: null,
			network: null,
			selectedNetwork: null,
			dyOpts: {
				labels: ['Timestamp', 'Upstream', 'Downstream'],
				labelsKMB: true,
				strokeWidth: 1.5,
				fillGraph: true,
				fillAlpha: 0.06,
				series: {
					Downstream: {
						color: '#54a5ff',
					},
				},
				axes: {
					y: {
						valueFormatter: function(i) {
							var val = i;
							if (i >= 1000000000) val = Math.round((i / 1000000000) * 100) / 100 + 'G';
							else if (i > 1000000) val = Math.round((i / 1000000) * 100) / 100 + 'M';
							else if (i > 1000) val = Math.round((i / 1000) * 100) / 100 + 'k';
							return val + 'bps';
						},
						axisLabelFormatter: function(i) {
							var val = i;
							if (i >= 1000000000) val = Math.round((i / 1000000000) * 100) / 100 + 'G';
							else if (i > 1000000) val = Math.round((i / 1000000) * 100) / 100 + 'M';
							else if (i > 1000) val = Math.round((i / 1000) * 100) / 100 + 'k';
							return val;
						},
					},
				},
			},
		};
	},
	computed: {
		networks() {
			if (!this.networkData) {
				return [{ value: null, text: 'Loading...' }];
			}
			return this.networkData.map(network => network.name).sort();
		},
	},
	methods: {
		changeNetwork() {
			this.network = this.networkData.find(net => net.name === this.selectedNetwork);
		},
	},
	created() {
		this.$http.get('idirect/traffic/allnetworks').then(resp => {
			this.networkData = process(resp.data.data);
			this.network = this.networkData[0];
			this.selectedNetwork = this.networkData[0].name;
			this.loaded = true;
		});
	},
};
</script>
